<template>
  <div class="relative" @mouseover="isShow = true" @mouseleave="isShow = false">
    <slot />

    <TransitionOpacity v-if="props.name && !props.disabled">
      <div v-if="isShow"
        class="absolute top-[2.25rem] left-0 translate-x-[-25%] px-2 py-1 rounded-xl bg-black/50 font-bold text-sm w-max"
        :class="props.className">
        <span>{{ props.name }}</span>
      </div>
    </TransitionOpacity>
  </div>
</template>

<script setup>
const props = defineProps({
  name: {
    type: String,
    default: '',
  },
  className: {
    type: String,
    default: 'text-white',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const isShow = ref(false)

</script>